import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Header } from '../Containers/Header'
import { Navigate, Outlet } from 'react-router-dom'

const queryClient = new QueryClient()

const RootRoute = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Header />
        <Outlet />
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export const RootIndexRoute = () => {
  return <Navigate to="/home" />
}

export default RootRoute;
