import axios from "axios"
import { auth } from "../Utils/firebase"
import { API_URL } from "./config"

export const authenticatedClient = axios.create({
    baseURL: API_URL,
})

authenticatedClient.interceptors.request.use(
    async (reqConfig) => {
        const idToken = await auth?.currentUser?.getIdToken()
        reqConfig.headers.Authorization = `Bearer ${idToken}`
        console.log('using idToken on req', reqConfig.url, idToken)
        return reqConfig
    }
)
