import { queryOptions, useMutation, useQueryClient } from "@tanstack/react-query"
import { BudgetAccount } from "../Models/Budget"
import { authenticatedClient as httpClient } from "../Utils/http"

const fetchBudgetAccounts = async (): Promise<BudgetAccount[]> =>
    (await httpClient.get<{ result: BudgetAccount[] }>("/api/budgets/accounts"))
        .data.result

export const fetchBudgetAccountsOptions = () =>
    queryOptions({
        queryKey: ['fetchBudgetAccounts'],
        queryFn: fetchBudgetAccounts,
    })

const createBudgetAccount = async (ba: BudgetAccount): Promise<BudgetAccount> =>
    (await httpClient.post<BudgetAccount>('/api/budgets/accounts', ba)).data

export const useCreateBudgetAccount = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: createBudgetAccount,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['fetchBudgetAccounts'] })
        },
    })
}

const updateBudgetAccount = async (ba: BudgetAccount): Promise<BudgetAccount> =>
    (await httpClient.put<BudgetAccount>(`/api/budgets/accounts/${ba.id}`, ba)).data

export const useUpdateBudgetAccount = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: updateBudgetAccount,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['fetchBudgetAccounts'] })
            queryClient.invalidateQueries({ queryKey: ['transactionCategories'] })
            queryClient.invalidateQueries({ queryKey: ['transactions'] })
        },
    })
}