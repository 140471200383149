import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import { useFileUploadMutation, useTryParseUploadMutation, useImportTransactionsMutation, ImportTransactionsResponse } from "../../State/Transactions";
import DisplayError from "../../Components/DisplayError";
import LoadingView from "../../Components/Loading";

// todo: use this instead of string
// type ColumnType = "date" | "text" | "amount"

type UploadSuccessHandler = (uploadedFileId: string) => void

type FileUploadViewProps = { uploadSuccessHandler: UploadSuccessHandler }

const FileUploadView = ({ uploadSuccessHandler }: FileUploadViewProps): React.JSX.Element => {

    const fileUploader = useFileUploadMutation()

    useEffect(() => {
        if (fileUploader.isSuccess) {
            uploadSuccessHandler(fileUploader.data.id)
        }
    }, [uploadSuccessHandler, fileUploader.isSuccess, fileUploader.data?.id])

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            fileUploader.mutate(e.target.files[0])
        }
    }

    if (fileUploader.isPending) {
        return <LoadingView />
    }

    return (
        <div className="container">
            <div className="row">
                {fileUploader.isError && (<DisplayError error={fileUploader.error} />)}
                <label htmlFor="fileUpload" className="form-label">Choose a CSV file to upload</label>
                <input className="form-control form-control-lg" id="fileUpload" type="file" onChange={onFileChange} accept=".csv" />
            </div>
        </div>
    )
}

type DoneViewProps = { importResponse: ImportTransactionsResponse }

const DoneView = ({ importResponse }: DoneViewProps): React.JSX.Element => {
    return (
        <div className="alert alert-success" role="alert">
            <div>Successfully imported {importResponse.count} transactions, between {importResponse.minDateImported} and {importResponse.maxDateImported}</div>
        </div>
    )
}

const TransactionsUploadScreen = (): React.JSX.Element => {

    const [fileId, setFileId] = useState<string | null>(null);

    const [parseError, setParseError] = useState<string | null>(null);
    const [parsedLines, setParsedLines] = useState<Array<Array<string>> | null>(null);
    const [firstParsedLine, setFirstParsedLine] = useState<Array<string> | null>(null);
    const [parsedColumnsCount, setParsedColumnsCount] = useState<number>(0);

    const [encoding, setEncoding] = useState<string>("ISO 8859-1");
    const [dateFormat, setDateFormat] = useState<string>("02.01.2006");
    const [recordDelimiter, setRecordDelimiter] = useState<string>(",");
    const [skipFirstLine, setSkipFirstLine] = useState<boolean>(false);
    const [columnTypes, setColumnTypes] = useState<Array<string>>([]);

    const [parseCallEnabled, setParseCallEnabled] = useState<boolean>(false);
    const [importCallEnabled, setImportCallEnabled] = useState<boolean>(false);

    const [importResponse, setImportResponse] = useState<ImportTransactionsResponse>();

    const transactionsParser = useTryParseUploadMutation()
    const transactionsImporter = useImportTransactionsMutation()

    const isParsed = !!parsedLines

    const clearParsedState = () => {
        setParsedLines(null)
        setFirstParsedLine(null)
        setParsedColumnsCount(0)
        setParseError(null)
    }

    useEffect(() => {
        clearParsedState()
        if (transactionsParser.isSuccess) {
            const anyFirstLine = !transactionsParser.data.parsedLines || transactionsParser.data.parsedLines.length <= 0 ? null : transactionsParser.data.parsedLines[0]
            const anyFirstLineLength = anyFirstLine == null ? 0 : anyFirstLine.length
            setImportCallEnabled(transactionsParser.data.ok === true)
            setParseError(transactionsParser.data.ok ? null : transactionsParser.data.error)
            setParsedLines(transactionsParser.data.parsedLines)
            setFirstParsedLine(anyFirstLine)
            setParsedColumnsCount(anyFirstLineLength)

            // only reset selected columnTypes if number of columns changed
            if (columnTypes.length < anyFirstLineLength) {
                setColumnTypes(arrayOf(anyFirstLineLength, ""))
            }
        }
    }, [transactionsParser.isSuccess, transactionsParser.data?.ok, transactionsParser.data?.error, transactionsParser.data?.parsedLines, columnTypes.length])

    useEffect(() => {
        if (transactionsImporter.isSuccess) {
            setImportResponse(transactionsImporter.data)
        }
    }, [transactionsImporter.isSuccess, transactionsImporter.data])

    const arrayOf = (length: number, value: string): Array<string> => {
        if (length <= 0) {
            return []
        }

        const arr = new Array(length)
        for (let i = 0; i < length; i++) {
            arr[i] = value
        }

        return arr
    }

    const onColumnTypeChange = (idx: number, type: string) => {
        setColumnTypes((oldValue) => oldValue.map((ctValue, ctIdx) => {
            if (ctIdx === idx) {
                // set the new value for this index
                return type
            }
            if (type === ctValue) {
                // only one column can have a given type
                return ""
            }
            // keep other columns' types as-is, if of a different type
            return ctValue
        }))
    }

    const getColumnTypeIndex = useCallback((type: string): number => columnTypes.findIndex((val) => val === type), [columnTypes])

    useEffect(() => {
        if (!fileId || recordDelimiter.length !== 1) {
            setParseCallEnabled(false)
            return
        }

        if ((!!parsedLines && parsedLines.length > 0) && (getColumnTypeIndex("date") === -1 || getColumnTypeIndex("text") === -1 || getColumnTypeIndex("amount") === -1)) {
            setParseCallEnabled(false)
            return
        }

        setParseCallEnabled(true)
    }, [fileId, recordDelimiter, parsedLines, getColumnTypeIndex])

    const onParseClick = () => {
        if (fileId && parseCallEnabled) {
            transactionsParser.mutate({ fileId, recordDelimiter, encoding, dateFormat, skipFirstLine, dateColumn: getColumnTypeIndex("date"), textColumn: getColumnTypeIndex("text"), amountColumn: getColumnTypeIndex("amount") })
        }
    }

    const onImportClick = () => {
        if (fileId && importCallEnabled) {
            transactionsImporter.mutate({ fileId, recordDelimiter, encoding, dateFormat, skipFirstLine, dateColumn: getColumnTypeIndex("date"), textColumn: getColumnTypeIndex("text"), amountColumn: getColumnTypeIndex("amount") })
        }
    }

    if (!fileId) {
        return <FileUploadView uploadSuccessHandler={(uploadedFileId: string) => setFileId(uploadedFileId)} />
    }

    if (importResponse) {
        return <DoneView importResponse={importResponse} />
    }
    if (transactionsImporter.isPending) {
        return <LoadingView />
    }
    if (transactionsImporter.isError) {
        return <DisplayError error={transactionsImporter.error} />
    }

    if (transactionsParser.isPending) {
        return <LoadingView />
    }
    if (transactionsParser.isError) {
        return <DisplayError error={transactionsParser.error} />
    }

    return (
        <div className="container">
            <div className="row">
                <div className="card" style={{ marginBottom: "1em" }}>
                    <div className="card-body">
                        <form>
                            <div className="form-group row">
                                <label htmlFor="skipFirstLine" className="col-sm-2 col-form-label">Skip first line?</label>
                                <div className="col-sm-10">
                                    <input className="form-check-input" type="checkbox" id="skipFirstLine" checked={skipFirstLine} onChange={(e) => setSkipFirstLine(!skipFirstLine)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="encoding" className="col-sm-2 col-form-label">File Encoding</label>
                                <div className="col-sm-10">
                                    <select className="form-control" id="encoding" value={encoding} onChange={(e) => setEncoding(e.target.value)}>
                                        <option></option>
                                        <option>Windows 1252</option>
                                        <option>Macintosh</option>
                                        <option>ISO 8859-1</option>
                                        <option>IBM Code Page 865</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="dateFormat" className="col-sm-2 col-form-label">Date Format</label>
                                <div className="col-sm-10">
                                    <select className="form-control" id="dateFormat" value={dateFormat} onChange={(e) => setDateFormat(e.target.value)}>
                                        <option>02.01.2006</option>
                                        <option>2006.01.02</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="recordDelimiter" className="col-sm-2 col-form-label">Record delimiter</label>
                                <div className="col-sm-10">
                                    <input id="recordDelimiter" type="text" className="form-control" value={recordDelimiter} size={1} maxLength={1} onChange={(e) => setRecordDelimiter(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-10">
                                    <button type="button" className={"btn " + (importCallEnabled ? "btn-secondary" : "btn-primary")} onClick={onParseClick} disabled={!parseCallEnabled} style={{ marginRight: "0.5em" }}>Process</button>
                                    <button type="button" className={"btn " + (importCallEnabled ? "btn-primary" : "btn-secondary")} onClick={onImportClick} disabled={!importCallEnabled}>Import</button>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-10">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {parseError && (
                    <div className="alert alert-warning" role="alert">
                        {parseError}
                    </div>
                )}

                {isParsed && firstParsedLine && parsedLines && parsedLines.length > 0 && parsedColumnsCount > 0 && (
                    <div className="container-fluid">
                        <div className="row">
                            {firstParsedLine.map((_, idx) => (
                                <div key={`parsed-header-col-${idx}`} className={`col-${Math.max(1, Math.floor(12 / firstParsedLine.length))}`}>
                                    <select value={columnTypes[idx]} onChange={(e) => onColumnTypeChange(idx, e.target.value)}>
                                        <option value=""></option>
                                        <option value="date">Date</option>
                                        <option value="text">Text</option>
                                        <option value="amount">Amount</option>
                                    </select>
                                </div>
                            ))}
                        </div>
                        {parsedLines.map((row, rowIdx) => (
                            <div key={`parsed-header-row-${rowIdx}`} className="row">
                                {row.map((cellValue, cellIdx) => (
                                    <div key={`parsed-header-row-${rowIdx}-cell-${cellIdx}`} className={`col-${Math.max(1, Math.floor(12 / row.length))}`}>{cellValue}</div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </div >
        </div>
    )
}

export default TransactionsUploadScreen
