import { useQuery } from "@tanstack/react-query"
import React, { useCallback, useEffect } from "react"
import { fetchTransactionsYearsOptions } from "../State/Transactions"

type TransactionsYearSelectorProps = {
    selectedYear: number
    yearSelectedHandler: (year: number) => void
}

const TransactionsYearSelector: React.FC<TransactionsYearSelectorProps> = ({ selectedYear, yearSelectedHandler }) => {
    const yearsQuery = useQuery(fetchTransactionsYearsOptions())

    const onChangeHandler = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => yearSelectedHandler(+e.target.value), [yearSelectedHandler])

    // Trigger a change of year if the given selectedYear is not in the list of years.
    // E.g. if default-value of "selectedYear" does not exist.
    useEffect(() => {
        if (yearSelectedHandler && yearsQuery?.data?.findIndex((value: number) => value === selectedYear) === -1 && yearsQuery?.data?.length > 0) {
            yearSelectedHandler(yearsQuery.data[0])
        }
    }, [selectedYear, yearsQuery.data, yearSelectedHandler])

    if (!yearsQuery.data) {
        return null
    }

    return (
        <div className="form-floating">
            <select id="transactionsYearSelector" className="form-select form-select-sm" value={selectedYear} onChange={onChangeHandler}>
                {yearsQuery.data.map((year) => <option key={`transactionsYearSelector-${year}`}>{year}</option>)}
            </select>
            <label htmlFor="transactionsYearSelector">Year</label>
        </div>
    )
}

export default TransactionsYearSelector
