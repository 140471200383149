import { v4 as uuidv4 } from 'uuid'
import { BudgetAccount } from "./Budget"

export type TransactionCategoryType = 'income' | 'expense'

export interface TransactionCategoryData {
    id: string
    label: string
    type: TransactionCategoryType
    exclude: boolean
    budgetAccount?: BudgetAccount
}

export type TransactionCategoryId = string

export class TransactionCategory {
    id: TransactionCategoryId
    label: string
    type: TransactionCategoryType
    exclude: boolean
    budgetAccount?: BudgetAccount

    constructor(data: TransactionCategoryData) {
        this.id = data.id
        this.label = data.label
        this.type = data.type
        this.exclude = data.exclude
        this.budgetAccount = data.budgetAccount
    }
}

export interface TransactionData {
    id: string
    date: string
    text: string
    amount: number
    category?: TransactionCategoryData
    notes?: string
}

export class Transaction {
    id: string
    date: Date
    text: string
    amount: number
    category?: TransactionCategory
    notes?: string

    constructor(data: TransactionData) {
        this.id = data.id
        this.date = new Date(data.date)
        this.text = data.text
        this.amount = data.amount
        this.category = data.category ? new TransactionCategory(data.category) : undefined
        this.notes = data.notes
    }

    split(): Transaction {
        return new Transaction({
            id: uuidv4(),
            amount: 0,
            date: this.date.toISOString(),
            text: this.text,
        })
    }
}
