import React, { CSSProperties, useState } from "react"
import { fetchTransactionsOptions } from "../../State/Transactions"
import LoadingView from "../../Components/Loading"
import DisplayError from "../../Components/DisplayError"
import { Transaction, TransactionCategory, TransactionCategoryId } from "../../Models/Transactions"
import { useQuery } from "@tanstack/react-query"
import TransactionsYearSelector from "../../Components/TransactionsYearSelector"
import { WholeAmountTableCell } from "../../Components/AmountTableCell"

const tableHeadingStyle: CSSProperties = {
    textAlign: 'end',
}

const sectionLabelRowStyle: CSSProperties = {
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    borderTopWidth: '0px',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderBottom: '1px solid black',
}

const sumRowStyle: CSSProperties = {
    fontWeight: 'bolder',
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
}

class Transactions {
    transactions: Transaction[] = []

    addTransaction(txn: Transaction) {
        this.transactions.push(txn)
    }

    summedAmount(): number {
        return this.transactions.reduce((accum, txn) => accum + txn.amount, 0) ?? 0
    }
}

class TransactionsMonths {
    // twelve months
    months: Transactions[] = [
        new Transactions(),
        new Transactions(),
        new Transactions(),
        new Transactions(),
        new Transactions(),
        new Transactions(),
        new Transactions(),
        new Transactions(),
        new Transactions(),
        new Transactions(),
        new Transactions(),
        new Transactions(),
    ]

    addTransaction(txn: Transaction) {
        this.months[txn.date.getMonth()].addTransaction(txn)
    }

    summedAmount(): number {
        return this.months.reduce((accum, txns) => accum + txns.summedAmount(), 0)
    }

    avgAmount(): number {
        return this.summedAmount() / 12
    }
}

class Section {
    label: string
    transactions: Transaction[] = []
    transactionsCategories: Map<TransactionCategoryId, TransactionCategory> = new Map()
    transactionsMonthsByCategory: Map<TransactionCategoryId, TransactionsMonths> = new Map()

    constructor(label: string) {
        this.label = label
    }

    addTransaction(txn: Transaction) {
        if (!txn.category?.id) {
            throw new Error("txn " + txn.id + " must have a category to be added")
        }

        this.transactions.push(txn)

        const categoryId = txn.category.id
        this.transactionsCategories.set(categoryId, txn.category)

        const months = this.transactionsMonthsByCategory.get(categoryId) ?? new TransactionsMonths()
        months.addTransaction(txn)
        this.transactionsMonthsByCategory.set(categoryId, months)
    }

    entries(): Array<[TransactionCategory, TransactionsMonths]> {
        const result: Array<[TransactionCategory, TransactionsMonths]> = []
        this.transactionsMonthsByCategory.forEach((txnMonths, categoryId) => {
            const category = this.transactionsCategories.get(categoryId)
            if (!category) {
                throw new Error("categoryId " + categoryId + " not found when exptected to")
            }
            result.push([category, txnMonths])
        })
        return result
    }

    monthsSums(): number[] {
        const result: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]
        this.transactions.forEach((txn) => result[txn.date.getMonth()] += txn.amount)
        return result
    }

    summedAmount(): number {
        return this.monthsSums().reduce((accum, sum) => accum + sum, 0)
    }

    avgAmount(): number {
        return this.summedAmount() / 12
    }
}

class Sections {
    incomes: Section = new Section("Indkomst")
    budgetedExpenses: Section = new Section("Budgetterede udgifter")
    variableExpenses: Section = new Section("Variable udgifter")
    excludedTransactions: Section = new Section("Ikke medregnet")
    uncategorizedTransactions: Transaction[] = []

    addTransaction(txn: Transaction) {
        if (!txn.category) {
            this.uncategorizedTransactions.push(txn)
            return
        }

        if (txn.category.exclude) {
            this.excludedTransactions.addTransaction(txn)
            return
        }

        if (txn.category.type === 'income') {
            this.incomes.addTransaction(txn)
            return
        }

        if (txn.category.budgetAccount) {
            this.budgetedExpenses.addTransaction(txn)
            return
        }

        this.variableExpenses.addTransaction(txn)
    }

    hasUncategorizedTransactions(): boolean {
        return this.uncategorizedTransactions.length > 0
    }
}

const TableSectionView = ({ section }: { section: Section }): React.JSX.Element => (
    <>
        <tr style={sectionLabelRowStyle}>
            <td colSpan={15}>{section.label}</td>
        </tr>
        {Array.from(section.entries()).map(([category, txnMonths]) => (
            <tr key={`section-${category.label}`}>
                <td>{category.budgetAccount ? category.budgetAccount.label + ", " : ""}{category.label}</td>
                {txnMonths.months.map((txns, idx) => <WholeAmountTableCell value={txns.summedAmount()} key={`section-${category.label}-months-${idx}`} />)}
                <WholeAmountTableCell value={txnMonths.summedAmount()} />
                <WholeAmountTableCell value={txnMonths.avgAmount()} />
            </tr>
        ))}
        <tr style={sumRowStyle}>
            <td>Ialt</td>
            {Array.from(section.monthsSums()).map((sum, idx) => <WholeAmountTableCell value={sum} key={`section-sums-${idx}`} />)}
            <WholeAmountTableCell value={section.summedAmount()} />
            <WholeAmountTableCell value={section.avgAmount()} />
        </tr>
    </>
)

const TableResultView = ({ sections }: { sections: Sections }): React.JSX.Element => {
    const budgetedExpensesMonthsSums = sections.budgetedExpenses.monthsSums()
    const availForVarExpensesMonthsSums = sections.incomes.monthsSums().map(
        (sum, idx) => sum + budgetedExpensesMonthsSums[idx]
    )
    const availForVarExpensesTotalSum = sections.incomes.summedAmount() + sections.budgetedExpenses.summedAmount()
    const availForVarExpensesTotalAvg = availForVarExpensesTotalSum / 12

    const varExpensesMonthsSums = sections.variableExpenses.monthsSums()
    const surplusMonthsSums = availForVarExpensesMonthsSums.map(
        (sum, idx) => sum + varExpensesMonthsSums[idx]
    )
    const surplusTotalSum = availForVarExpensesTotalSum + sections.variableExpenses.summedAmount()
    const surplusTotalAvg = surplusTotalSum / 12

    return (
        <>
            <tr style={sectionLabelRowStyle}>
                <td colSpan={15}>Resultat</td>
            </tr>
            <tr className="table-dark">
                <td>Rådighedsbeløb</td>
                {availForVarExpensesMonthsSums.map((sum, idx) => <WholeAmountTableCell value={sum} key={`section-results-avail4varexp-months-${idx}`} />)}
                <WholeAmountTableCell value={availForVarExpensesTotalSum} />
                <WholeAmountTableCell value={availForVarExpensesTotalAvg} />
            </tr>
            <tr className="table-dark">
                <td>Overskud</td>
                {surplusMonthsSums.map((sum, idx) => <WholeAmountTableCell value={sum} key={`section-results-surplus-months-${idx}`} />)}
                <WholeAmountTableCell value={surplusTotalSum} />
                <WholeAmountTableCell value={surplusTotalAvg} />
            </tr>
        </>
    )
}

const DividerRow = (): React.JSX.Element => (
    <tr style={{ borderWidth: '0px' }}>
        <td colSpan={15}>&nbsp;</td>
    </tr>
)

const DashboardScreen = (): React.JSX.Element | null => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const transactions = useQuery(fetchTransactionsOptions(selectedYear))

    if (transactions.isPending) {
        return (<LoadingView />)
    }

    if (transactions.isError) {
        return (<DisplayError error={transactions.error} />)
    }

    if (!transactions.data) {
        return null
    }

    const sections: Sections = transactions.data.reduce(
        (accum, txn) => {
            accum.addTransaction(txn)
            return accum
        },
        new Sections()
    )

    return (
        <div className="container">
            <div className="table-responsive">
                <table className="table table-hover table-bordered table-sm">
                    <thead>
                        <tr>
                            <th colSpan={15}>
                                <div className="row">
                                    <div className="col-1">
                                        <TransactionsYearSelector selectedYear={selectedYear} yearSelectedHandler={(year) => setSelectedYear(year)} />
                                    </div>
                                </div>
                                {sections.hasUncategorizedTransactions() && (
                                    <div className="row">
                                        <div className="col text-danger">
                                            You have uncategorized transactions in this year
                                        </div>
                                    </div>)}
                            </th>
                        </tr>
                        <tr className="table-dark" style={tableHeadingStyle}>
                            <th scope="col"></th>

                            <th scope="col">Januar</th>
                            <th scope="col">Februar</th>
                            <th scope="col">Marts</th>
                            <th scope="col">April</th>
                            <th scope="col">Maj</th>
                            <th scope="col">Juni</th>
                            <th scope="col">Juli</th>
                            <th scope="col">August</th>
                            <th scope="col">September</th>
                            <th scope="col">Oktober</th>
                            <th scope="col">November</th>
                            <th scope="col">December</th>

                            <th scope="col">Ialt</th>
                            <th scope="col">Gennemsnit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <DividerRow />
                        <TableSectionView section={sections.incomes} />
                        <DividerRow />
                        <TableSectionView section={sections.budgetedExpenses} />
                        <DividerRow />
                        <TableSectionView section={sections.variableExpenses} />
                        <DividerRow />
                        <TableResultView sections={sections} />
                        <DividerRow />
                        <TableSectionView section={sections.excludedTransactions} />
                    </tbody>
                </table>
            </div >
        </div>
    )
}

export default DashboardScreen
