type ErrorDef = {
    error: Error
}
const DisplayError = ({ error }: ErrorDef) => {
    console.log(error)
    return (
        <div>
            <div>{error.name}</div>
            <div>{error.message}</div>
        </div>
    )
}

export default DisplayError