import React, { useCallback, useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { fetchTransactionCategoriesOptions, useCreateTransactionCategory, useUpdateTransactionCategory } from "../../State/TransactionCategories"
import DisplayError from "../../Components/DisplayError"
import LoadingView from "../../Components/Loading"
import { TransactionCategory, TransactionCategoryType } from "../../Models/Transactions"
import { fetchBudgetAccountsOptions } from "../../State/BudgetAccounts"
import { v4 as uuidv4 } from 'uuid'

export type TransactionCategoriesProps = {}

const TransactionCategories: React.FC<TransactionCategoriesProps> = () => {

    const transactionCategories = useQuery(fetchTransactionCategoriesOptions())
    const budgetAccounts = useQuery(fetchBudgetAccountsOptions())
    const transactionCategoryCreator = useCreateTransactionCategory()
    const transactionCategoryUpdater = useUpdateTransactionCategory()

    const [transactionCategoryRows, setTransactionCategoryRows] = useState<TransactionCategory[] | undefined>([])

    // state values for creating a new category
    const [labelNew, setLabelNew] = useState('')
    const [typeNew, setTypeNew] = useState<TransactionCategoryType | undefined>()
    const [budgetAccountIdNew, setBudgetAccountIdNew] = useState<string>('')
    const [excludeNew, setExcludeNew] = useState<boolean>(false)
    const [addFormValid, setAddFormValid] = useState<boolean>(false)

    useEffect(() => {
        setTransactionCategoryRows(transactionCategories.data)
    }, [transactionCategories.data])

    useEffect(() => {
        setAddFormValid(!!labelNew && labelNew !== '' && !!typeNew)
    }, [labelNew, typeNew])

    const onAddNewClicked = useCallback(() => {
        transactionCategoryCreator.mutate({
            id: uuidv4(),
            label: labelNew,
            type: typeNew!,
            budgetAccount: budgetAccounts?.data?.find((ba) => ba.id === budgetAccountIdNew),
            exclude: excludeNew,
        }, {
            onSuccess: () => {
                setLabelNew('')
                setTypeNew(undefined)
                setBudgetAccountIdNew('')
                setExcludeNew(false)
            }
        })
    }, [labelNew, typeNew, budgetAccountIdNew, excludeNew, budgetAccounts?.data, transactionCategoryCreator])

    if (transactionCategories.isError) {
        return (<DisplayError error={transactionCategories.error} />)
    }

    if (budgetAccounts.isError) {
        return (<DisplayError error={budgetAccounts.error} />)
    }

    if (transactionCategories.isPending || budgetAccounts.isPending) {
        return (<LoadingView />)
    }

    const saveTransactionCategoryLabel = (cat: TransactionCategory, value: string) => {
        cat.label = value
        transactionCategoryUpdater.mutate(cat)
    }

    const saveTransactionCategoryType = (cat: TransactionCategory, value: TransactionCategoryType) => {
        cat.type = value
        transactionCategoryUpdater.mutate(cat)
    }

    const saveTransactionCategoryBudgetAccount = (cat: TransactionCategory, value: string) => {
        cat.budgetAccount = budgetAccounts.data.find((ba) => ba.id === value)
        transactionCategoryUpdater.mutate(cat)
    }

    const saveTransactionCategoryExclude = (cat: TransactionCategory, value: boolean) => {
        cat.exclude = value
        transactionCategoryUpdater.mutate(cat)
    }

    transactionCategories.data.sort((cat1, cat2) => cat1.label.localeCompare(cat2.label))

    return (
        <div>
            <form className="row row-cols-lg-auto g-3 align-items-center" style={{ marginBottom: '1em' }}>
                <div>
                    <div className="input-group">
                        <input className="form-control" value={labelNew} onChange={(e) => setLabelNew(e.currentTarget.value)} placeholder="Label..." />
                    </div>
                </div>

                <div>
                    <select className="form-select" value={typeNew} onChange={(e) => setTypeNew(e.currentTarget.value as TransactionCategoryType)}>
                        <option value="">Type...</option>
                        <option value="income">Indkomst</option>
                        <option value="expense">Udgift</option>
                    </select>
                </div>

                <div>
                    <select className="form-select" value={budgetAccountIdNew} onChange={(e) => setBudgetAccountIdNew(e.currentTarget.value)}>
                        <option value="">Budgetkonto...</option>
                        {budgetAccounts.data.map((budgetAccount) => (
                            <option key={`newTxnCat-budgetAccount-${budgetAccount.id}-option`} value={budgetAccount.id}>{budgetAccount.label}</option>
                        ))}
                    </select>
                </div>

                <div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={excludeNew} id='newTxnCat-excluded' onChange={(e) => setExcludeNew(e.currentTarget.checked)} />
                        <label className="form-check-label" htmlFor='newTxnCat-excluded'>
                            Excluded?
                        </label>
                    </div>
                </div>

                <div className="col-12">
                    <button type="button" className="btn btn-primary" disabled={!addFormValid} onClick={onAddNewClicked}>Add</button>
                </div>
            </form>

            {transactionCategoryRows?.map((cat) => (
                <ul className="list-group list-group-horizontal" key={`txnCat-${cat.id}`}>
                    <li className="list-group-item">
                        <input className="form-control form-control-sm" defaultValue={cat.label} onBlur={(e) => saveTransactionCategoryLabel(cat, e.currentTarget.value)}></input>
                    </li>
                    <li className="list-group-item">
                        <select className="form-select form-select-sm" defaultValue={cat.type} onChange={(e) => saveTransactionCategoryType(cat, e.currentTarget.value as TransactionCategoryType)}>
                            <option value="income">Indkomst</option>
                            <option value="expense">Udgift</option>
                        </select>
                    </li>
                    <li className="list-group-item">
                        <select className="form-select form-select-sm" defaultValue={cat.budgetAccount?.id} onChange={(e) => saveTransactionCategoryBudgetAccount(cat, e.currentTarget.value)}>
                            <option value=""></option>
                            {budgetAccounts.data.map((budgetAccount) => (
                                <option key={`txnCat-${cat.id}-budgetAccounts-${budgetAccount.id}-option`} value={budgetAccount.id}>{budgetAccount.label}</option>
                            ))}
                        </select>
                    </li>
                    <li className="list-group-item">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultChecked={cat.exclude} id={`txnCat-${cat.id}-excluded`} onChange={(e) => saveTransactionCategoryExclude(cat, e.currentTarget.checked)} />
                            <label className="form-check-label" htmlFor={`${cat.id}-excluded`}>
                                Excluded?
                            </label>
                        </div>
                    </li>
                </ul>
            ))}
        </div>
    )
}

export default TransactionCategories