import { FirebaseOptions, initializeApp } from 'firebase/app'
import { GoogleAuthProvider, User, UserCredential, getAuth, onAuthStateChanged, signInWithPopup, signOut as fbSignOut } from 'firebase/auth'

console.log('firebase init')
const firebaseConfig: FirebaseOptions = {
    apiKey: 'AIzaSyAjdYv2DYPvkL0F-2nXyZ6Es1VwND2ZmnI',
    authDomain: 'zahldo.firebaseapp.com',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

onAuthStateChanged(
    auth,
    (user: User | null) => {
        if (user) {
            console.log('signed in', user)
        } else {
            console.log('signed out')
        }
    }
)

const provider = new GoogleAuthProvider()
provider.addScope('https://www.googleapis.com/auth/userinfo.email')

export const signIn = async () =>
    signInWithPopup(auth, provider)
        .then((user: UserCredential) => {
            console.log(`signIn: logged in (${user.operationType} via ${user.providerId}) - rendering app`, user)
        })
        .catch((reason) => {
            console.error('signIn: failed signin', reason)
        })

export const signOut = () => fbSignOut(auth)

export default app