import React, { CSSProperties } from "react"
import { formatFractionalNumber, formatWholeNumber } from "../Utils/Intl"

const numberCellStyle: CSSProperties = {
    textAlign: 'end',
}

export type AmountTableCellProps = {
    value: number
    style?: CSSProperties
    includeFractions?: boolean
}

export const AmountTableCell: React.FC<AmountTableCellProps> = ({ value, style, includeFractions = true }): React.JSX.Element => (
    <td style={{ ...numberCellStyle, ...style }}>
        {(includeFractions ? formatFractionalNumber : formatWholeNumber)(value)}
    </td>
)

export const WholeAmountTableCell: React.FC<AmountTableCellProps> = (props): React.JSX.Element => (
    <AmountTableCell {...props} includeFractions={false} />
)
