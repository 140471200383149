import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { Transaction } from "../../Models/Transactions"
import React, { useEffect, useState } from "react"
import { formatFractionalNumber, parseNumber } from "../../Utils/Intl"

export type TransactionSplitCancelledListener = (txn: Transaction) => void
export type TransactionSplitListener = (existingTxn: Transaction, addedTxn: Transaction) => void

export type TransactionSplitModalOptions = {
    transaction: Transaction
    splitTransactionCancelHandler: TransactionSplitCancelledListener
    splitTransactionSplitHandler: TransactionSplitListener
}

const TransactionSplitModal: React.FC<TransactionSplitModalOptions> = ({
    transaction,
    splitTransactionCancelHandler,
    splitTransactionSplitHandler,
}): React.JSX.Element | null => {
    const [origTxnAmountText, setOrigTxnAmountText] = useState(formatFractionalNumber(transaction.amount))
    const [origTxnAmount, setOrigTxnAmount] = useState(transaction.amount)

    const [addedTxnAmountText, setAddedTxnAmountText] = useState('0')
    const [addedTxnAmount, setAddedTxnAmount] = useState(0)

    const [splitBtnEnabled, setSplitBtnEnabled] = useState(false)

    useEffect(() => {
        if (isNaN(origTxnAmount) || isNaN(addedTxnAmount)) {
            setSplitBtnEnabled(false)
            return
        }

        if (addedTxnAmount === 0) {
            setSplitBtnEnabled(false)
            return
        }

        if (transaction.amount - (origTxnAmount + addedTxnAmount) !== 0) {
            setSplitBtnEnabled(false)
            return
        }

        setSplitBtnEnabled(true)
    }, [transaction.amount, origTxnAmount, addedTxnAmount])

    const onAmountChangedHandler = (amountRawValue: string) => {
        amountRawValue = amountRawValue.trim()
        if (amountRawValue === '' || amountRawValue === '-') {
            setAddedTxnAmountText(amountRawValue)
            setAddedTxnAmount(0)
            return
        }

        const numberValue = parseNumber(amountRawValue)

        if (isNaN(numberValue)) {
            setAddedTxnAmountText('')
            setAddedTxnAmount(0)
            return
        }

        setAddedTxnAmountText(formatFractionalNumber(numberValue))
        setAddedTxnAmount(numberValue)

        setOrigTxnAmountText(formatFractionalNumber(transaction.amount - numberValue))
        setOrigTxnAmount(transaction.amount - numberValue)
    }

    const onSplit = () => {
        const addedTxn = transaction.split()
        addedTxn.amount = addedTxnAmount
        transaction.amount = origTxnAmount
        splitTransactionSplitHandler(transaction, addedTxn)
    }

    return (
        <Modal show={!!transaction} onHide={() => splitTransactionCancelHandler(transaction)} className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Split Transaction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col>
                            <Form.Control disabled={true} readOnly={true} defaultValue={transaction.date.toLocaleDateString()} />
                        </Col>
                        <Col>
                            <Form.Control disabled={true} readOnly={true} defaultValue={transaction.text} />
                        </Col>
                        <Col>
                            <Form.Control disabled={true} readOnly={true} value={origTxnAmountText} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control disabled={true} readOnly={true} defaultValue={transaction.date.toLocaleDateString()} />
                        </Col>
                        <Col>
                            <Form.Control disabled={true} readOnly={true} defaultValue={transaction.text} />
                        </Col>
                        <Col>
                            <Form.Control value={addedTxnAmountText} onChange={(e) => onAmountChangedHandler(e.target.value)}/>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => splitTransactionCancelHandler(transaction)}>
                    Cancel
                </Button>
                <Button variant="primary" disabled={!splitBtnEnabled} onClick={onSplit}>
                    Split
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TransactionSplitModal
