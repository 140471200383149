import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css';
import RootRoute, { RootIndexRoute } from './Routes/RootRoute';
import reportWebVitals from './reportWebVitals';
import ErrorRoute from './Routes/ErrorPage';
import { TransactionsRoute } from './Routes/TransactionsRoute';
import { TransactionsUploadRoute } from './Routes/TransactionsUploadRoute';
import { DashboardRoute } from './Routes/DashboardRoute';
import { BudgetRoute } from './Routes/BudgetRoute';
import { HomeRoute } from './Routes/HomeRoute';
import { RequireAuth } from './Routes/RequiresAuth';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootRoute />,
    errorElement: <ErrorRoute />,
    children: [
      {
        index: true,
        element: <RootIndexRoute />,
      },
      {
        path: "home",
        element: <HomeRoute />,
      },
      {
        path: "dashboard",
        element: <RequireAuth><DashboardRoute /></RequireAuth>,
      },
      {
        path: "transactions",
        element: <RequireAuth><TransactionsRoute /></RequireAuth>,
      },
      {
        path: "transactions/upload",
        element: <RequireAuth><TransactionsUploadRoute /></RequireAuth>,
      },
      {
        path: "budget",
        element: <RequireAuth><BudgetRoute /></RequireAuth>,
      },
    ],
  },
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
