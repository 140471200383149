import React from "react"
import { Tab, Tabs } from "react-bootstrap"
import BudgetAccounts from "./BudgetAccounts"
import TransactionCategories from "./TransactionCategories"

export type BudgetScreenProps = {}

const BudgetScreen: React.FC<BudgetScreenProps> = () => {

    return (
        <div className="container">
            <Tabs defaultActiveKey="categories">
                <Tab title="Kategorisering" eventKey="categories">
                    <div style={{ marginTop: "1em" }}>
                        <TransactionCategories />
                    </div>
                </Tab>
                <Tab title="Budget" eventKey="budget">
                    <div style={{ marginTop: "1em" }}>
                        <BudgetAccounts />
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
}

export default BudgetScreen