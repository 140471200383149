import { queryOptions, useMutation, useQueryClient } from "@tanstack/react-query"
import { TransactionCategory, TransactionCategoryData } from "../Models/Transactions"
import { authenticatedClient as httpClient } from "../Utils/http"

const fetchTransactionCategories = async (): Promise<TransactionCategory[]> => {
    const response = await httpClient.get<{ result: TransactionCategoryData[] }>("/api/transactions/categories")
    return response.data.result.map(
        (data) => new TransactionCategory(data)
    )
}

export const fetchTransactionCategoriesOptions = () =>
    queryOptions({
        queryKey: ['transactionCategories'],
        queryFn: fetchTransactionCategories,
    })

const createTransactionCategory = async (txnCat: TransactionCategory): Promise<TransactionCategory> => {
    const response = await httpClient.post<TransactionCategoryData>('/api/transactions/categories', txnCat)
    return new TransactionCategory(response.data)
}
    
export const useCreateTransactionCategory = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: createTransactionCategory,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['transactionCategories'] })
        },
    })
}

const updateTransactionCategory = async (txnCat: TransactionCategory): Promise<TransactionCategory> => {
    const response = await httpClient.put<TransactionCategoryData>(`/api/transactions/categories/${txnCat.id}`, txnCat)
    return new TransactionCategory(response.data)
}

export const useUpdateTransactionCategory = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: updateTransactionCategory,
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['transactions'] })
            queryClient.invalidateQueries({ queryKey: ['transactionCategories'] })
        },
    })
}
