import { useQuery } from "@tanstack/react-query"
import React, { useCallback, useEffect } from "react"
import { format } from "date-fns"
import { fetchTransactionsMonthsOptions } from "../State/Transactions"

type TransactionsYearSelectorProps = {
    year: number
    selectedMonth: number
    monthSelectedHandler: (month: number) => void
}

const TransactionsMonthSelector: React.FC<TransactionsYearSelectorProps> = ({ year, selectedMonth, monthSelectedHandler }) => {
    const monthsQuery = useQuery(fetchTransactionsMonthsOptions(year))

    const onChangeHandler = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        monthSelectedHandler(+e.target.value)
    }, [monthSelectedHandler])

    // trigger a change of month if the given selectedMonth is not in the list of months for the year
    useEffect(() => {
        if (selectedMonth !== -1 && monthSelectedHandler && monthsQuery?.data?.findIndex((value: number) => value === selectedMonth) === -1 && monthsQuery?.data?.length > 0) {
            monthSelectedHandler(monthsQuery.data[0])
        }
    }, [selectedMonth, monthsQuery.data, monthSelectedHandler])

    const getMonthName = (month: number) => format(new Date(2000, month, 1), 'MMMM')

    if (!monthsQuery.data) {
        return null
    }

    // convert into zero-based months matching JS-format and add names
    const months = monthsQuery.data.map((monthValue) => ({
        monthValue: monthValue,
        monthName: getMonthName(monthValue - 1),
    }))

    months.unshift({
        monthValue: -1,
        monthName: 'All'
    })

    return (
        <div className="form-floating">
            <select id="transactionsMonthSelector" className="form-select form-select-sm" value={selectedMonth} onChange={onChangeHandler}>
                {months.map((month) => <option key={`transactionsMonthSelector-${month.monthName}`} value={month.monthValue}>{month.monthName}</option>)}
            </select>
            <label htmlFor="transactionsMonthSelector">Month</label>
        </div>
    )
}

export default TransactionsMonthSelector
