class NumberParser {
  _group?: RegExp
  _decimal?: RegExp
  _numeral: RegExp
  _index: (d: string) => string

  constructor(locale: string) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6)
    const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse()
    const index = new Map<string, number>(numerals.map((d, i) => [d, i]));

    const groupPart = parts.find(d => d.type === "group")
    if (groupPart) {
      this._group = new RegExp(`[${groupPart.value}]`, "g");
    }

    const decimalPart = parts.find(d => d.type === "decimal")
    if (decimalPart) {
      this._decimal = new RegExp(`[${decimalPart.value}]`);
    }

    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = (d: string) => index.get(d) === undefined ? '' : d + '';
  }

  parse(s: string) {
    s = s.trim()
    if (this._group) {
      s = s.replace(this._group, '')
    }
    if (this._decimal) {
      s = s.replace(this._decimal, '.')
    }
    s = s.replace(this._numeral, this._index)
    return +s
  }
}

const wholeNumberFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 })
const fractionalNumberFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 2 })

const numberParser = new NumberParser(fractionalNumberFormatter.resolvedOptions().locale)

export const formatWholeNumber = (n: number): string => wholeNumberFormatter.format(n)

export const formatFractionalNumber = (n: number): string => fractionalNumberFormatter.format(n)

export const parseNumber = (s: string): number => numberParser.parse(s)
