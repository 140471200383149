import { Navigate, useLocation } from "react-router"
import { useAuthState } from "react-firebase-hooks/auth"
import DisplayError from "../Components/DisplayError"
import { auth } from "../Utils/firebase"

export const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();
  const loginLocation = '/home' // todo: Make a /login route?

  if (loading) {
    console.log('auth is loading', location)
    return null
  }

  if (error) {
    console.log('auth is error', location, error)
    return <DisplayError error={error} />
  }

  if (!user) {
    console.log('not authenticated - redirecting from', location, 'to', loginLocation)
    return <Navigate to={loginLocation} state={{ from: location }} />;
  }

  console.log('authenticated as', user)
  return children;
}
