import { Typeahead } from "react-bootstrap-typeahead"
import { Option } from "react-bootstrap-typeahead/types/types"
import { Transaction, TransactionCategory } from "../../Models/Transactions"
import { TransactionChangedListener, TransactionSplitRequestedListener } from "./TransactionsList"
import { ChangeEvent, ChangeEventHandler, useState } from "react"
import { AmountTableCell } from "../../Components/AmountTableCell"

type TransactionsListItemProps = {
    transaction: Transaction
    transactionCategories: TransactionCategory[]
    onTransactionChanged: TransactionChangedListener
    onTransactionSplitRequested: TransactionSplitRequestedListener
}

const TransactionsListRow = ({ transaction, transactionCategories, onTransactionChanged, onTransactionSplitRequested }: TransactionsListItemProps) => {
    const category = transaction.category
    const selectedCategory = category ? [category] : []

    const [notes, setNotes] = useState(transaction.notes)

    const onCategoryChange = (selected: Option[]) => {
        const anyNewCategory = selected?.length === 1 ? selected[0] : undefined
        const newCategory = anyNewCategory ? anyNewCategory as TransactionCategory : undefined
        transaction.category = newCategory
        onTransactionChanged(transaction)
    }

    const onNotesChange: ChangeEventHandler = (event:ChangeEvent<HTMLInputElement>) => setNotes(event.currentTarget.value)

    const onNotesBlur = () => {
        if (transaction.notes !== notes) {
            transaction.notes = notes
            onTransactionChanged(transaction)
        }
    }

    return (
        <tr>
            <td>{transaction.date.toLocaleDateString()}</td>
            <td>{transaction.text}</td>
            <AmountTableCell value={transaction.amount} style={{ color: transaction.amount < 0 ? "darkred" : "green" }}/>
            <td>
                <Typeahead
                    id={`todo-list-item-category-${transaction.id}`}
                    flip={true}
                    highlightOnlyResult={true}
                    options={transactionCategories}
                    placeholder="Vælg en kategori..."
                    onChange={onCategoryChange}
                    selected={selectedCategory}
                    clearButton={true}
                />
            </td>
            <td>
                <input value={notes} style={{ width: "100%" }} onChange={onNotesChange} onBlur={onNotesBlur} />
            </td>
            <td>
                <i className="bi bi-layout-split" title="Split transaction" onClick={() => onTransactionSplitRequested(transaction)} />
            </td>
        </tr>
    )
}

export default TransactionsListRow