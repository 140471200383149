import React from "react";
import TransactionsListRow from "./TransactionsListRow";
import { Transaction, TransactionCategory } from "../../Models/Transactions";

export type TransactionChangedListener = (txn: Transaction) => void
export type TransactionSplitRequestedListener = (txn: Transaction) => void

type TransactionsListProps = {
    onTransactionChanged: TransactionChangedListener
    onTransactionSplitRequested: TransactionSplitRequestedListener
    transactions: Transaction[]
    transactionCategories: TransactionCategory[]
}

const TransactionsList = ({ transactions, transactionCategories, onTransactionChanged, onTransactionSplitRequested }: TransactionsListProps): React.JSX.Element => {
    return (
        <tbody>
            {transactions.map((e) => (
                <TransactionsListRow
                    key={`expense-list-item-${e.id}`}
                    transaction={e}
                    transactionCategories={transactionCategories}
                    onTransactionChanged={onTransactionChanged}
                    onTransactionSplitRequested={onTransactionSplitRequested}
                />
            ))}
        </tbody>
    )
}

export default TransactionsList
