import React from "react"
import { useAuthState } from "react-firebase-hooks/auth";
import DisplayError from "../../Components/DisplayError"
import { auth, signIn } from "../../Utils/firebase"
import { Button, Container } from "react-bootstrap";
import { User } from "firebase/auth";

const AuthenticatedHome: React.FC<{ user: User }> = ({ user }) => <div>Welcome {user.displayName} / {user.email}</div>

const AnonymousHome: React.FC = () => <Button onClick={() => signIn()} type="button">Log in with Google</Button>

const HomeScreen: React.FC = () => {
    const [user, loading, error] = useAuthState(auth);

    if (loading) {
        console.log('auth is loading')
        return null
    }

    if (error) {
        console.log('auth is error', error)
        return <DisplayError error={error} />
    }

    return (
        <Container>
            {user ? <AuthenticatedHome user={user} /> : <AnonymousHome />}
        </Container>
    )
}

export default HomeScreen