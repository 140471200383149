import { useMutation, useQueryClient, queryOptions } from "@tanstack/react-query"
import { Transaction, TransactionData } from "../Models/Transactions"
import { AxiosResponse } from "axios"
import { authenticatedClient as httpClient } from "../Utils/http"

const fetchTransactions = async (year?: number, month?: number): Promise<Transaction[]> => {
    month = month === -1 ? undefined : month
    const response = await httpClient.get<{ result: TransactionData[] }>(
        "/api/transactions",
        {
            params: { year, month }
        }
    )
    return response.data.result.map(
        (data) => new Transaction(data)
    )
}

export const fetchTransactionsOptions = (year?: number, month?: number) =>
    queryOptions({
        queryKey: ['transactions', { year, month }],
        queryFn: () => fetchTransactions(year, month),
    })

const fetchTransactionsYears = async (): Promise<number[]> => {
    return (await httpClient.get<{ result: number[] }>(
        "/api/transactions/years"
    )).data.result
}

export const fetchTransactionsYearsOptions = () =>
    queryOptions({
        queryKey: ['transactions', 'years'],
        queryFn: fetchTransactionsYears,
    })

const fetchTransactionsMonths = async (year: number): Promise<number[]> => {
    return (await httpClient.get<{ result: number[] }>(
        `/api/transactions/${year}/months`
    )).data.result
}

export const fetchTransactionsMonthsOptions = (year: number) =>
    queryOptions({
        queryKey: ['transactions', year, 'months'],
        queryFn: () => fetchTransactionsMonths(year),
    })

const splitTransaction = async ({transaction, addedTransaction}: {transaction: Transaction, addedTransaction: Transaction}) =>
    httpClient.post(`/api/transactions/${transaction.id}/split`, {
        transaction,
        addedTransaction,
    })

export const useSplitTransaction = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: splitTransaction,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['transactions'] })
            queryClient.invalidateQueries({ queryKey: ['transactionCategories'] })
        },
    })
}

const updateTransaction = async (txn: Transaction): Promise<Transaction> => {
    const response = await httpClient.put<TransactionData>(`/api/transactions/${txn.id}`, txn)
    return new Transaction(response.data)
}

export const useMutateTransaction = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: updateTransaction,
        onSuccess: (data, variables) => {
            queryClient.setQueryData(['transactions', { id: variables.id }], data)
            queryClient.invalidateQueries({ queryKey: ['transactions'] })
            queryClient.invalidateQueries({ queryKey: ['transactionCategories'] })
        },
    })
}

export type UploadTransactionsResponse = {
    id: string,
}

const uploadTransactions = async (file: File): Promise<UploadTransactionsResponse> => {
    var formData = new FormData()
    formData.append('file', file)
    const response = await httpClient.post<UploadTransactionsResponse>(
        '/api/transactions/uploads',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
    return response.data
}

export const useFileUploadMutation = () =>
    useMutation({ mutationFn: uploadTransactions })


export type TryParseUploadRequest = {
    encoding: string,
    skipFirstLine: boolean
    dateFormat: string,
    recordDelimiter: string,
    dateColumn: number,
    textColumn: number,
    amountColumn: number,
}

export type TryParseUploadResponse = {
    ok: boolean,
    error: string,
    parsedLines: Array<Array<string>>,
}

const tryParseUpload = async (params: { fileId: string } & TryParseUploadRequest): Promise<TryParseUploadResponse> => {
    const response = await httpClient.post<TryParseUploadResponse, AxiosResponse<TryParseUploadResponse>, TryParseUploadRequest>(
        `/api/transactions/uploads/${params.fileId}/try-parse`,
        {
            encoding: params.encoding,
            skipFirstLine: params.skipFirstLine,
            dateFormat: params.dateFormat,
            recordDelimiter: params.recordDelimiter,
            dateColumn: params.dateColumn,
            textColumn: params.textColumn,
            amountColumn: params.amountColumn,
        },
        {

            transitional: {
                clarifyTimeoutError: false
            }
        }
    )
    return response.data
}

export const useTryParseUploadMutation = () =>
    useMutation({ mutationFn: tryParseUpload })

export type ImportTransactionsRequest = TryParseUploadRequest

export type ImportTransactionsResponse = {
    count: number,
    minDateImported: string,
    maxDateImported: string,
}

const importTransactions = async (params: { fileId: string } & ImportTransactionsRequest): Promise<ImportTransactionsResponse> => {
    const response = await httpClient.post<ImportTransactionsRequest, AxiosResponse<ImportTransactionsResponse>, ImportTransactionsRequest>(
        `/api/transactions/uploads/${params.fileId}/import`,
        {
            encoding: params.encoding,
            skipFirstLine: params.skipFirstLine,
            dateFormat: params.dateFormat,
            recordDelimiter: params.recordDelimiter,
            dateColumn: params.dateColumn,
            textColumn: params.textColumn,
            amountColumn: params.amountColumn,
        },
    )
    return response.data
}

export const useImportTransactionsMutation = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: importTransactions,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['transactions'] })
            queryClient.invalidateQueries({ queryKey: ['transactions', 'years'] })
            queryClient.invalidateQueries({ queryKey: ['transactionCategories'] })
        },
    })
}
