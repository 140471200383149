import { useQuery } from "@tanstack/react-query"
import React, { useCallback, useEffect, useState } from "react"
import { fetchBudgetAccountsOptions, useCreateBudgetAccount, useUpdateBudgetAccount } from "../../State/BudgetAccounts"
import DisplayError from "../../Components/DisplayError"
import LoadingView from "../../Components/Loading"
import { v4 as uuidv4 } from 'uuid'
import { formatFractionalNumber, parseNumber } from "../../Utils/Intl"
import { BudgetAccount } from "../../Models/Budget"

export type BudgetAccountsProps = {}

const BudgetAccounts: React.FC<BudgetAccountsProps> = () => {

    const budgetAccounts = useQuery(fetchBudgetAccountsOptions())
    const budgetAccountCreator = useCreateBudgetAccount()
    const budgetAccountUpdater = useUpdateBudgetAccount()

    const [labelNew, setLabelNew] = useState('')
    const [amountNewText, setAmountNewText] = useState('0')
    const [amountNew, setAmountNew] = useState(0)
    const [addFormValid, setAddFormValid] = useState<boolean>(false)

    useEffect(() => {
        setAddFormValid(!!labelNew && labelNew !== '' && !isNaN(amountNew) && amountNew !== 0)
    }, [labelNew, amountNew])

    const onAddNewClicked = useCallback(() => {
        budgetAccountCreator.mutate({
            id: uuidv4(),
            label: labelNew,
            amount: amountNew,
        }, {
            onSuccess: () => {
                setLabelNew('')
                setAmountNewText('0')
                setAmountNew(0)
            }
        })
    }, [labelNew, amountNew, budgetAccountCreator])

    const onAmountChangedHandler = (amountRawValue: string) => {
        amountRawValue = amountRawValue.trim()
        if (amountRawValue === '' || amountRawValue === '-') {
            setAmountNewText(amountRawValue)
            setAmountNew(0)
            return
        }

        const numberValue = parseNumber(amountRawValue)

        if (isNaN(numberValue)) {
            setAmountNewText('')
            setAmountNew(0)
            return
        }

        setAmountNewText(formatFractionalNumber(numberValue))
        setAmountNew(numberValue)
    }

    if (budgetAccounts.isError) {
        return <DisplayError error={budgetAccounts.error} />
    }

    if (budgetAccounts.isPending) {
        return <LoadingView />
    }

    const saveBudgetAccountLabel = (ba: BudgetAccount, value: string) => {
        ba.label = value
        budgetAccountUpdater.mutate(ba)
    }

    const saveBudgetAccountAmount = (ba: BudgetAccount, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const numberValue = parseNumber(value)

        if (isNaN(numberValue)) {
            console.warn('invalid amount', value)
            event.target.value = formatFractionalNumber(ba.amount)
            return
        }

        ba.amount = numberValue
        event.target.value = formatFractionalNumber(numberValue)

        budgetAccountUpdater.mutate(ba)
    }

    return (
        <div>
            <form className="row row-cols-lg-auto g-3 align-items-center" style={{ marginBottom: '1em' }}>
                <div>
                    <div className="input-group">
                        <input className="form-control" value={labelNew} onChange={(e) => setLabelNew(e.currentTarget.value)} placeholder="Label..." />
                    </div>
                </div>
                <div>
                    <div className="input-group">
                        <input className="form-control" value={amountNewText} onChange={(e) => onAmountChangedHandler(e.target.value)} placeholder="Amount..." />
                    </div>
                </div>
                <div className="col-12">
                    <button type="button" className="btn btn-primary" disabled={!addFormValid} onClick={onAddNewClicked}>Add</button>
                </div>
            </form>

            {budgetAccounts.data.map((ba) => (
                <ul className="list-group list-group-horizontal" key={`budget-account-${ba.id}`}>
                    <li className="list-group-item">
                        <input className="form-control form-control-sm" defaultValue={ba.label} onBlur={(e) => saveBudgetAccountLabel(ba, e.currentTarget.value)}></input>
                    </li>
                    <li className="list-group-item">
                        <input className="form-control form-control-sm" defaultValue={formatFractionalNumber(ba.amount)} onBlur={(e) => saveBudgetAccountAmount(ba, e)}></input>
                    </li>
                </ul>

            ))}
        </div>
    )
}

export default BudgetAccounts