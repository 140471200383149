import React, { useState } from "react";
import { Transaction } from "../../Models/Transactions";
import LoadingView from "../../Components/Loading";
import DisplayError from "../../Components/DisplayError";
import TransactionsList, { TransactionChangedListener, TransactionSplitRequestedListener } from "./TransactionsList";
import { fetchTransactionCategoriesOptions } from "../../State/TransactionCategories";
import { fetchTransactionsOptions, useSplitTransaction, useMutateTransaction } from "../../State/Transactions";
import { useQuery } from "@tanstack/react-query";
import TransactionsYearSelector from "../../Components/TransactionsYearSelector";
import TransactionsMonthSelector from "../../Components/TransactionsMonthSelector";
import TransactionSplitModal, { TransactionSplitCancelledListener, TransactionSplitListener } from "./TransactionSplitModal";

const TransactionsScreen = (): React.JSX.Element => {

    const transactionCategories = useQuery(fetchTransactionCategoriesOptions())
    const transactionUpdater = useMutateTransaction()
    const transactionSplitter = useSplitTransaction()
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth())
    const [sortAscending, setSortAscending] = useState<boolean>(true)
    const [transactionToSplit, setTransactionToSplit] = useState<Transaction | undefined>()
    const transactions = useQuery(fetchTransactionsOptions(selectedYear, selectedMonth))

    const onTransactionChangedListener: TransactionChangedListener = (txn: Transaction) => {
        transactionUpdater.mutate(txn)
    }

    const onTransactionSplitRequestedListener: TransactionSplitRequestedListener = (txn: Transaction) => {
        // will render and show split modal dialog
        setTransactionToSplit(txn)
    }

    const onTransactionSplit: TransactionSplitListener = (transaction: Transaction, addedTransaction: Transaction) => {
        transactionSplitter.mutate({ transaction, addedTransaction }, {
            onSuccess: () => setTransactionToSplit(undefined)
        })
    }

    const onTransactionSplitCancel: TransactionSplitCancelledListener = (txn: Transaction) => {
        setTransactionToSplit(undefined)
    }

    if (transactions.isPending || transactionCategories.isPending) {
        return (<LoadingView />)
    }

    if (transactionCategories.isError) {
        return (<DisplayError error={transactionCategories.error} />)
    }
    if (transactions.isError) {
        return (<DisplayError error={transactions.error} />)
    }

    transactions.data.sort((txn1, txn2) => {
        const result = txn1.date.getTime() < txn2.date.getTime()
            ? -1
            : txn1.date.getTime() > txn2.date.getTime()
                ? 1
                : 0
        return sortAscending ? result : result * -1
    })

    return (
        <>
            <div className="container">
                <div className="table-responsive">
                    <table className="table table-hover table-bordered table-sm">
                        <thead>
                            <tr>
                                <th colSpan={15}>
                                    <div className="row">
                                        <div className="col-1">
                                            <TransactionsMonthSelector year={selectedYear} selectedMonth={selectedMonth} monthSelectedHandler={(month) => setSelectedMonth(month)} />
                                        </div>
                                        <div className="col-1">
                                            <TransactionsYearSelector selectedYear={selectedYear} yearSelectedHandler={(year) => setSelectedYear(year)} />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr className="table-dark">
                                <th className="col-1" onClick={() => setSortAscending(!sortAscending)}>Dato</th>
                                <th className="col-4" style={{ textAlign: "left" }}>Tekst</th>
                                <th className="col-1" style={{ textAlign: "right" }}>Beløb</th>
                                <th className="col-2" style={{ textAlign: "left" }}>Kategori</th>
                                <th className="col" style={{ textAlign: "left" }}>Noter</th>
                                <th className="col" style={{ textAlign: "left" }}>&nbsp;</th>
                            </tr>
                        </thead>
                        <TransactionsList
                            transactions={transactions.data}
                            transactionCategories={transactionCategories.data}
                            onTransactionChanged={onTransactionChangedListener}
                            onTransactionSplitRequested={onTransactionSplitRequestedListener}
                            />
                    </table>
                </div>
            </div>

            {transactionToSplit ? (<TransactionSplitModal
                transaction={transactionToSplit}
                splitTransactionSplitHandler={onTransactionSplit}
                splitTransactionCancelHandler={onTransactionSplitCancel}
            />) : null}

        </>
    )
}

export default TransactionsScreen